'use client';
import ActivityFeeds from '@/lib/components/ActivityFeed';
import SummaryCard from '@/lib/components/AdminSummaryCard';
import DashboardTrips from '@/lib/components/DashboardTrips';
import StatisticsGraph from '@/lib/components/StatisticsGraph';
import { Grid } from '@mui/material';

export default function ConsolePage() {
  return (
    <Grid container columnSpacing={4} rowSpacing={4}>
      <Grid item xs={12} lg={4}>
        <SummaryCard
          title="Total Applications"
          total={5672}
          sharePercentage={14}
          chartPercentage={74}
          color="success"
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <SummaryCard
          title="Shortlisted Candidates"
          total={3045}
          sharePercentage={14}
          chartPercentage={74}
          color="warning"
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <SummaryCard
          title="Approved Candidates"
          total={3045}
          sharePercentage={14}
          chartPercentage={74}
          color="error"
        />
      </Grid>

      <Grid item xs={12}>
        <StatisticsGraph />
      </Grid>

      <Grid item xs={12} lg={7}>
        <ActivityFeeds />
      </Grid>

      <Grid item xs={12} lg={5}>
        <DashboardTrips />
      </Grid>
    </Grid>
  );
}
