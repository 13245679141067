'use client';

import DashboardTripItem from '@/lib/components/DashboardTripItem';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';
import { ComponentProps, useMemo } from 'react';

export default function DashboardTrips() {
  const router = useRouter();
  const trips: Array<ComponentProps<typeof DashboardTripItem>> = useMemo(() => {
    return [
      {
        title: 'Interview',
        meetingDate: new Date(),
        startTime: '9:00am',
        endTime: '11:30am',
      },
      {
        title: 'Interview',
        meetingDate: new Date(),
        startTime: '9:00am',
        endTime: '11:30am',
      },
      {
        title: 'Interview',
        meetingDate: new Date(),
        startTime: '9:00am',
        endTime: '11:30am',
      },
    ];
  }, []);

  return (
    <Card>
      <Stack>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={700}>
            Trips
          </Typography>

          <Button
            size="small"
            color="inherit"
            variant="outlined"
            startIcon={<AirlineStopsOutlinedIcon />}
            onClick={() => {
              router.push('admin/trips/create');
            }}
          >
            Create Trip
          </Button>
        </Box>

        <Box display="flex" flexDirection="column">
          {trips.map((item) => (
            <DashboardTripItem
              key={item.title}
              title={item.title}
              meetingDate={item.meetingDate}
              startTime={item.startTime}
              endTime={item.endTime}
            />
          ))}
        </Box>
      </Stack>
    </Card>
  );
}
